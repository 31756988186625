import React from 'react';
import layoutStyles from '../../../../stylesheets/components/layout.module.scss';
import svgStyles from '../../../../stylesheets/components/svg.module.scss';
import classNames from 'classnames';

const CircleCheckLargeIcon = () => {
  return (
    <svg
      className={classNames(layoutStyles.mb30, svgStyles.svgStrokeBlue)}
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.4062 23.9375L26.5211 41L17.5938 32.4688"
        stroke="#1472FF"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M31 60.5C47.1543 60.5 60.25 47.4043 60.25 31.25C60.25 15.0957 47.1543 2 31 2C14.8457 2 1.75 15.0957 1.75 31.25C1.75 47.4043 14.8457 60.5 31 60.5Z"
        stroke="#1472FF"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

export default CircleCheckLargeIcon;
